import React from 'react';
import tw, {styled} from 'twin.macro';
import {Container} from '../Grid';

const Wrapper = styled.div`
  ${tw`flex-row`};
  margin-bottom: 50px;
`;

const Content = styled.div`
  ${tw`flex-col`}
  height: 100%;
  padding: 70px 40px;
  width: 100%;
  padding-top: 0;
  padding-bottom: ${props => props.paddingBottom};

  h1 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 127%;
    color: #224b45;
    margin-bottom: 30px;
    text-align: center;
    margin-bottom: 43px;

    @media ${props => props.theme.screens.md} {
      font-size: 40px;
    }
  }
  h2 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    text-align: center;
    color: #224b45;
    margin-bottom: 62px;
  }
  p {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    color: #224b45;
  }

  a {
    color: #039259;
    text-decoration: underline;
  }

  ul {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    list-style-type: disc;
    margin-left: 18px;

    li {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 32px;
      color: #224b45;
      margin-bottom: 20px;
    }
  }

  ol {
    list-style: numbers;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    li {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 32px;
      color: #224b45;
      margin-bottom: 20px;
    }
  }
`;
const List = ({
  title,
  subline,
  underline,
  list = [],
  objectList,
  orderedLists,
  content = "",
  style = {}
}) => {
  const copy = [...list];
  const firstArray = list.length > 5 ? copy.splice(0, 5) : list;
  const secondArray = list.length > 5 ? list.splice(-5) : [];
  return (
    <Container>
      <Wrapper>
        <Content {...style}>
          <h1>{title}</h1>
          {subline && <h2 dangerouslySetInnerHTML={{__html: subline}} />}
          {content && <p dangerouslySetInnerHTML={{__html: content}} />}
          <>
            {orderedLists &&
              orderedLists.map(orderedList => {
                return (
                  <div key={orderedList.title} style={{marginTop: 30}}>
                    <span style={{marginBottom: 20, display: 'block'}}>
                      <strong style={{fontSize: 24}}>
                        {orderedList.title}
                      </strong>
                    </span>
                    <ul>
                      {orderedList.items.map(item => (
                        <li
                          key={item}
                          dangerouslySetInnerHTML={{__html: item}}
                        />
                      ))}
                    </ul>
                  </div>
                );
              })}
          </>

          {objectList && (
            <>
              {objectList.map(item => {
                return (
                  <div
                    style={{marginBottom: 20}}
                    key={item}
                    dangerouslySetInnerHTML={{__html: item}}
                  />
                );
              })}
            </>
          )}
          <ol>
            <div>
              {firstArray?.map(l => (
                <li dangerouslySetInnerHTML={{__html: l}} />
              ))}
            </div>
            {secondArray?.length > 0 && (
              <div>
                {secondArray.map(l => (
                  <li dangerouslySetInnerHTML={{__html: l}} />
                ))}
              </div>
            )}
          </ol>
          {underline && (
            <h2
              style={{marginTop: 50}}
              dangerouslySetInnerHTML={{__html: underline}}
            />
          )}
        </Content>
      </Wrapper>
    </Container>
  );
};

export default List;
