import React from 'react';
import tw, {styled} from 'twin.macro';

const Wrapper = styled.div`
  ${tw`flex-row`};
  margin-bottom: 80px;
  position: relative;
`;

const Content = styled.div`
  ${tw`flex-col`}
  height: 100%;
  background: #f8f8f8;
  padding: 30px;
  width: 100%;
  margin-bottom: 150px;
  text-align: justify;
  padding-bottom: 0;

  @media ${props => props.theme.screens.md} {
    padding: 100px 40px;
    padding-left: 100px;
    margin-bottom: 250px;
  }
  @media ${props => props.theme.screens.lg} {
    width: ${props => (props.img ? '70%' : '100%')};
    padding-left: 190px;
    margin-bottom: 50px;
  }

  h1 {
    font-family: ${props => props.theme.fonts.quicksand};
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 127%;
    color: #224b45;
    margin-bottom: 50px;
    text-align: left;
    width: 95%;

    @media ${props => props.theme.screens.md} {
      font-size: 40px;
    }
  }
  p {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 32px;
    color: #224b45;
  }

  a {
    color: #039259;
    text-decoration: underline;
  }
`;

const ParagraphWrapper = styled.div`
  padding-bottom: 150px;
  padding-right: 0;

  @media ${props => props.theme.screens.md} {
    padding-right: 70px;
  }

  @media ${props => props.theme.screens.lg} {
    padding-bottom: 50px;
    padding-right: 150px;
  }
`;

const ImgWrapper = styled.div`
  position: absolute;
  height: 250px;
  width: 250px;
  right: 0;
  bottom: -120px;
  max-height: 550px;

  @media ${props => props.theme.screens.md} {
    height: 300px;
    width: 40%;
    right: 100px;
    top: 85%;
  }

  @media ${props => props.theme.screens.lg} {
    height: 70%;
    width: 35%;
    right: 0;
    top: 15%;
  }
`;

const Img = styled.div`
  background: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  background-position: 50%;
`;
const TextImage = ({title, img, children}) => {
  return (
    <Wrapper>
      <Content img={img}>
        <h1>{title}</h1>
        <ParagraphWrapper>
          <p>{children}</p>
        </ParagraphWrapper>
        {img && (
          <ImgWrapper>
            <Img
              src={
                img?.childrenImageSharp[0].gatsbyImageData.images.fallback.src
              }
            />
          </ImgWrapper>
        )}
      </Content>
    </Wrapper>
  );
};

export default TextImage;
